import React from "react"
import { graphql } from "gatsby"
import { useQueryParam, NumberParam, StringParam } from "use-query-params"

import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import theme from "../themes/theme"
import * as EmailValidator from "email-validator"

import { PageLayout } from "../components/PageLayout"

import {
  // HeroGrid,
  // HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroExtra,
  // HeroButton,
} from "../components/HeroSection"

import { FormButtonSecondary } from "../components/Buttons"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
} from "../components/SectionBlocks"

import { FaRegUser, FaMoneyCheckAlt } from "react-icons/fa"
import {
  AiOutlineMail,
  AiOutlineMobile,
  AiOutlineClockCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai"
import { GoDeviceMobile } from "react-icons/go"
import { FiTarget } from "react-icons/fi"
import { BiCurrentLocation, BiHelpCircle, BiStopwatch } from "react-icons/bi"
import { BsPersonCheck, BsReplyAllFill } from "react-icons/bs"
import { IoMaleFemale } from "react-icons/io5"

// import Bio from "../components/bio"
// import Layout from "../components/layout"
import SEO from "../components/seo"
import { Fragment } from "react"
// import { render } from "react-dom"

const PageMoreInfo = ({ data, location }) => {
  // const [fname, setFname] = useQueryParam("f", StringParam)
  // const [email, setEmail] = useQueryParam("e", StringParam)
  // const [mobile, setMobile] = useQueryParam("m", StringParam)

  return (
    <PageLayout>
      <SEO
        title="Club Bunker - Your fitness goals"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Helping Club Bunker members achieve their best"
        url={location.origin + location.pathname}
      />

      <div className="relative w-full z-10 ">
        <div className="absolute inset-0 -z-20 w-full">
          <Img
            fluid={data?.heroImageMitchAlex?.childImageSharp?.fluid}
            alt="Club Bunker"
            objectFit="cover"
            className="md:-z-10 hidden md:block h-full"
          />

          {/* <GatsbyImage
            image={getImage(data?.heroImageMitchAlex2)}
            className="md:-z-10 hidden md:block md:opacity-30 h-full"
          /> */}
        </div>
        <div className="z-20">
          <div className="md:max-w-[50%]">
            <div className="m-2 md:m-8 bg-primaryBlueNormal rounded-xl">
              <div className="p-4 md:p-8 text-center">
                <p className="font-bold text-4xl text-center text-LPwhite text-center md:text-left font-serif">
                  <DisplayWelcomeLine />
                </p>
                <p className="font-normal text-base text-center text-LPwhite text-center md:text-left font-serif">
                  So we can make the most of our time together, we'd love to learn a little bit more
                  about your goals and fitness history.
                </p>
                <MoreInfoForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default PageMoreInfo

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    handstandImage: file(absolutePath: { regex: "/mitch-handstand-dark-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1200, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImageMitchAlex: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-1920x874.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImageMitchAlex2: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-1920x874.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          aspectRatio: 1.77
          formats: [AVIF, AUTO, WEBP]
          placeholder: BLURRED
        )
      }
    }

    clubBunkerLogo: file(absolutePath: { regex: "/ClubBunkerLogo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    clubBunkerLogoBlack: file(absolutePath: { regex: "/ClubBunkerLogo-black.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-squat-light-1920x874.jpg/" }) {
      childImageSharp {
        fixed(width: 1920, height: 874, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const DisplayWelcomeLine = () => {
  if (typeof window !== "undefined") {
    let firstName = localStorage.getItem("FirstName")
    return firstName + ", your free consult is CONFIRMED!"
  } else {
    return "Your free consult is CONFIRMED"
  }
}

class BigGoalComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onBigGoalChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_4">
            <FiTarget color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_4"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_4"
            aria-label="What is your big goal?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.goalBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What is your big goal right now?
            </option>
            <option value="6">Look good</option>
            <option value="7">Play longer</option>
            <option value="8">Lose weight</option>
            <option value="9">Be healthier</option>
          </select>
        </div>
      </div>
    )
  }
}

class FitnessSituationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onFitnessSituationChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_18">
            <BiCurrentLocation color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_18"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_18"
            aria-label="What is your fitness situation"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.fitnessSituationBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What best describes your current activity level?
            </option>
            <option value="34">
              I&#039;m mostly at my desk with little to no additional activity
            </option>
            <option value="35">Mostly at my desk but hit my 10,000 steps</option>
            <option value="36">I have a physically demanding job that keeps me moving</option>
            <option value="37">I work out or play sport at least once a week</option>
            <option value="38">I train twice or more a week</option>
          </select>
        </div>
      </div>
    )
  }
}

class WhenStartComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onWhenStartChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_11">
            <AiOutlineClockCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_11"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_11"
            aria-label="What is your fitness situation"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.whenStartBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              When are you looking to start?
            </option>
            <option value="22">Now</option>
            <option value="23">Later</option>
          </select>
        </div>
      </div>
    )
  }
}

class WhatExtraHelpComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onWhatExtraHelpChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_12">
            <BiHelpCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <textarea
            type="text"
            name="custom_12"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_12"
            cols="50"
            rows="5"
            aria-label="What do you need the most help with right now?"
            placeholder="What do you need the most help with right now?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.extraHelpBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}></textarea>
        </div>
      </div>
    )
  }
}

class GoalTimelineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onGoalTimelineChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_13">
            <BiStopwatch color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_13"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_13"
            aria-label="What is your goal timeline"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.goalTimelineBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What do you believe is a realistic timeline to achieve your goal?
            </option>
            <option value="24">12 months or greater</option>
            <option value="25">6 - 12 months</option>
            <option value="26">Less than 6 months</option>
          </select>
        </div>
      </div>
    )
  }
}

class DecisionMakerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onDecisionMakerChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_14">
            <BsPersonCheck color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_14"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_14"
            aria-label="Are you the decision maker?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.decisionMakerBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              Are you the financial decision maker?
            </option>
            <option value="27">Yes</option>
            <option value="28">No, I need to consult with my partner / significant other</option>
          </select>
        </div>
      </div>
    )
  }
}

class FinancialSituationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onFinancialSituationChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_15">
            <FaMoneyCheckAlt color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_15"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_15"
            aria-label="What best describes your current financial situation?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.financialSituationBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              What best describes your financial situation?
            </option>
            <option value="29">
              I have the financial resources now to invest in my health and fitness
            </option>
            <option value="30">
              I am broke and have no credit card to invest in my health and fitness
            </option>
          </select>
        </div>
      </div>
    )
  }
}

class PreferredTrainerGenderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onPreferredTrainerGenderChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          margin: "10px",
          padding: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_17">
            <IoMaleFemale color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <select
            type="text"
            name="custom_17"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_17"
            aria-label="Do you have a preferred trainer gender?"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.preferredTrainerGenderBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}>
            <option value="0" disabled selected>
              Do you have a preferred trainer gender
            </option>

            <option value="33">No preference</option>
            <option value="31">Male</option>
            <option value="32">Female</option>
          </select>
        </div>
      </div>
    )
  }
}

class AnythingElseToShareComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onAnythingElseToShareChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="custom_16">
            <AiOutlineQuestionCircle color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <textarea
            type="text"
            name="custom_16"
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="custom_16"
            cols="50"
            rows="4"
            aria-label="Is there anything else you feel is important and want to share?"
            placeholder="Is there anything else you feel is important and want to share?"
            style={{
              width: "100%",
              border: this.props.anythingElseToShareBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}></textarea>
        </div>
      </div>
    )
  }
}

class UserNameComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onUserNameChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userName">
            <FaRegUser color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            name="first_name"
            placeholder={"First name ..."}
            defaultValue={this.props.firstValue}
            // defaultValue={this.props.fname ? this.props.fname : ""}
            // value=""
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="userName"
            aria-label="First Name"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.userBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

class UserLastNameComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
    // if (this.props.fname) {
    //   this.value = this.props.fname
    // }
  }

  handleValueChange(e) {
    this.props.onUserLastNameChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          // border: "1px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userLastName">
            <FaRegUser color="white" fontSize="2.3rem" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            name="last_name"
            placeholder={"Last name ..."}
            defaultValue={this.props.firstValue}
            // defaultValue={this.props.fname ? this.props.fname : ""}
            // value=""
            className="js_kartra_santitation"
            data-santitation-type="name"
            id="userLastName"
            aria-label="Last Name"
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.userLastNameBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
            onChange={this.handleValueChange}
          />
        </div>
      </div>
    )
  }
}

class UserEmailComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserEmailChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userEmail">
            <AiOutlineMail color="white" fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="email"
            placeholder="Email address ..."
            // defaultValue={this.props.email ? this.props.email : ""}
            defaultValue={this.props.firstValue}
            name="email"
            id="userEmail"
            aria-label="Email Address"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.emailBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class UserMobileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  handleValueChange(e) {
    this.props.onUserMobileChange(e.target.value)
  }

  render() {
    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          border: "0px solid white",
          marginTop: "10px",
          paddingTop: "10px",
        }}>
        <div
          style={{
            paddingRight: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <label htmlFor="userMobile">
            <GoDeviceMobile color="white" fontSize="2.3rem" padding="5px" />
          </label>
        </div>
        <div style={{ width: "70%" }}>
          <input
            type="text"
            className="js_kartra_santitation"
            data-santitation-type="numeric"
            placeholder="Mobile phone ..."
            // defaultValue={this.props.mobile ? this.props.mobile : ""}
            defaultValue={this.props.firstValue}
            name="phone"
            id="userMobile"
            aria-label="Mobile Phone"
            onChange={this.handleValueChange}
            // style={{ border: "1px solid white", padding: "5px" }}
            style={{
              width: "100%",
              height: "3rem",
              border: this.props.mobileBorder,
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        </div>
      </div>
    )
  }
}

class MoreInfoForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleUserNameChange = this.handleUserNameChange.bind(this)
    this.handleUserEmailChange = this.handleUserEmailChange.bind(this)
    this.handleUserMobileChange = this.handleUserMobileChange.bind(this)
    this.handleBigGoalChange = this.handleBigGoalChange.bind(this)
    this.handleFitnessSituationChange = this.handleFitnessSituationChange.bind(this)
    this.handleWhenStartChange = this.handleWhenStartChange.bind(this)
    this.handleWhatExtraHelpChange = this.handleWhatExtraHelpChange.bind(this)
    this.handleGoalTimelineChange = this.handleGoalTimelineChange.bind(this)
    this.handleDecisionMakerChange = this.handleDecisionMakerChange.bind(this)
    this.handleFinancialSituationChange = this.handleFinancialSituationChange.bind(this)
    this.handlePreferredTrainerGenderChange = this.handlePreferredTrainerGenderChange.bind(this)
    this.handleAnythingElseToShareChange = this.handleAnythingElseToShareChange.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.handleLastNameChange = this.handleLastNameChange.bind(this)

    this.state = {
      validName: false,
      userBorder: "none",
      validEmail: false,
      emailBorder: "none",
      validMobile: false,
      mobileBorder: "none",
      validGoal: false,
      goalBorder: "none",
      validFitnessSituation: false,
      fitnessSituationBorder: "none",
      validWhenStart: false,
      whenStartBorder: "none",
      validExtraHelp: true,
      extraHelpBorder: "none",
      validGoalTimeline: false,
      goalTimelineBorder: "none",
      validDecisionMaker: false,
      decisionMakerBorder: "none",
      validFinancialSituation: false,
      financialSituationBorder: "none",
      validPreferredTrainerGender: false,
      preferredTrainerGenderBorder: "none",
      vallidAnythingElseToShare: false,
      anythingElseToShareBorder: "none",
      validLastName: false,
      userLastNameBorder: "none",
      firstName: "",
      email: "",
      lastName: "",
      mobileNumber: "",
      goal: "",
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("FirstName"))
        this.setState({
          firstName: localStorage.getItem("FirstName"),
          validName: true,
          userBorder: "2px solid green",
        })
      if (localStorage.getItem("Email"))
        this.setState({
          email: localStorage.getItem("Email"),
          validEmail: true,
          emailBorder: "2px solid green",
        })
      if (localStorage.getItem("LastName"))
        this.setState({
          lastName: localStorage.getItem("LastName"),
          validLastName: true,
          userLastNameBorder: "2px solid green",
        })
      if (localStorage.getItem("Mobile"))
        this.setState({
          mobileNumber: localStorage.getItem("Mobile"),
          validMobile: true,
          mobileBorder: "2px solid green",
        })
    }
  }

  handleLastNameChange(e) {
    if (e.length > 2) {
      this.setState({ validLastName: true, userLastNameBorder: "2px solid green", lastName: e })
    } else {
      this.setState({ validLastName: false, userLastNameBorder: "2px solid red" })
    }
  }

  handleSubmitClick(e) {
    if (typeof window !== "undefined") {
      localStorage.setItem("FirstName", this.state.firstName)
      localStorage.setItem("LastName", this.state.lastName)
      localStorage.setItem("Email", this.state.email)
      localStorage.setItem("Mobile", this.state.mobileNumber)
      switch (this.state.goal) {
        case "6":
          localStorage.setItem("Goal", "Look good")
          break
        case "7":
          localStorage.setItem("Goal", "Play longer")
          break
        case "8":
          localStorage.setItem("Goal", "Lose Weight")
          break
        case "9":
          localStorage.setItem("Goal", "Be healthier")
          break
        default: {
        }
      }
    }
  }

  handleUserNameChange(e) {
    if (e.length > 2) {
      this.setState({ validName: true, userBorder: "2px solid green", firstName: e })
    } else {
      this.setState({ validName: false, userBorder: "2px solid red" })
    }
  }

  handleUserEmailChange(e) {
    if (EmailValidator.validate(e)) {
      this.setState({ validEmail: true, emailBorder: "2px solid green", email: e })
    } else {
      this.setState({ validEmail: false, emailBorder: "2px solid red" })
    }
  }

  handleUserMobileChange(e) {
    if (e.length > 9) {
      this.setState({ validMobile: true, mobileBorder: "2px solid green", mobileNumber: e })
    } else {
      this.setState({ validMobile: false, mobileBorder: "2px solid red" })
    }
  }

  handleBigGoalChange(e) {
    if (e > 0) {
      this.setState({ validGoal: true, goalBorder: "2px solid green", goal: e })
    } else {
      this.setState({ validGoal: false, goalBorder: "2px solid red" })
    }
  }

  handleFitnessSituationChange(e) {
    if (e > 0) {
      this.setState({ validFitnessSituation: true, fitnessSituationBorder: "2px solid green" })
    } else {
      this.setState({ validFitnessSituation: false, fitnessSituationBorder: "2px solid red" })
    }
  }

  handleWhenStartChange(e) {
    if (e > 0) {
      this.setState({ validWhenStart: true, whenStartBorder: "2px solid green" })
    } else {
      this.setState({ validWhenStart: false, whenStartBorder: "2px solid red" })
    }
  }

  handleWhatExtraHelpChange(e) {
    if (e.length >= 0) {
      this.setState({ validExtraHelp: true, extraHelpBorder: "2px solid green" })
    } else {
      this.setState({ validExtraHelp: false, extraHelpBorder: "2px solid red" })
    }
  }

  handleGoalTimelineChange(e) {
    if (e > 0) {
      this.setState({ validGoalTimeline: true, goalTimelineBorder: "2px solid green" })
    } else {
      this.setState({ validGoalTimeline: false, goalTimelineBorder: "2px solid red" })
    }
  }

  handleDecisionMakerChange(e) {
    if (e > 0) {
      this.setState({ validDecisionMaker: true, decisionMakerBorder: "2px solid green" })
    } else {
      this.setState({ validDecisionMaker: false, decisionMakerBorder: "2px solid red" })
    }
  }

  handleFinancialSituationChange(e) {
    if (e > 0) {
      this.setState({ validFinancialSituation: true, financialSituationBorder: "2px solid green" })
    } else {
      this.setState({ validFinancialSituation: false, financialSituationBorder: "2px solid red" })
    }
  }

  handlePreferredTrainerGenderChange(e) {
    if (e > 0) {
      this.setState({
        validPreferredTrainerGender: true,
        preferredTrainerGenderBorder: "2px solid green",
      })
    } else {
      this.setState({
        validPreferredTrainerGender: false,
        preferredTrainerGenderBorder: "2px solid red",
      })
    }
  }

  handleAnythingElseToShareChange(e) {
    if (e.length >= 0) {
      this.setState({
        vallidAnythingElseToShare: true,
        anythingElseToShareBorder: "2px solid green",
      })
    } else {
      this.setState({
        vallidAnythingElseToShare: false,
        anythingElseToShareBorder: "2px solid red",
      })
    }
  }

  render() {
    return (
      <div>
        <form
          method="post"
          action="https://app.kartra.com/process/add_lead/TlIgZvdGWMdT"
          target="_top"
          className="form_class_EhjQ8txHlZa4 optin_form_for_video js_kartra_trackable_objec"
          data-kt-type="optin"
          data-kt-value="TlIgZvdGWMdT"
          data-kt-owner="Brl61K8g"
          acceptCharset="UTF-8">
          <div className="">
            <FitnessSituationComponent
              onFitnessSituationChange={this.handleFitnessSituationChange}
              fitnessSituationBorder={this.state.fitnessSituationBorder}
            />
            <BigGoalComponent
              goalBorder={this.state.goalBorder}
              onBigGoalChange={this.handleBigGoalChange}
            />
            <GoalTimelineComponent
              onGoalTimelineChange={this.handleGoalTimelineChange}
              goalTimelineBorder={this.state.goalTimelineBorder}
            />
            <WhenStartComponent
              onWhenStartChange={this.handleWhenStartChange}
              whenStartBorder={this.state.whenStartBorder}
            />
            <AnythingElseToShareComponent
              onAnythingElseToShareChange={this.handleAnythingElseToShareChange}
              anythingElseToShareBorder={this.state.anythingElseToShareBorder}
            />
            <UserNameComponent
              onUserNameChange={this.handleUserNameChange}
              userBorder={this.state.userBorder}
              firstValue={this.state.firstName}
            />
            <UserLastNameComponent
              onUserLastNameChange={this.handleLastNameChange}
              userLastNameBorder={this.state.userLastNameBorder}
              firstValue={this.state.lastName}
            />
            <UserEmailComponent
              onUserEmailChange={this.handleUserEmailChange}
              emailBorder={this.state.emailBorder}
              firstValue={this.state.email}
            />
            <UserMobileComponent
              onUserMobileChange={this.handleUserMobileChange}
              mobileBorder={this.state.mobileBorder}
              firstValue={this.state.mobileNumber}
            />
          </div>
          <div>
            <input
              type="hidden"
              id="custom_6"
              name="custom_6"
              className="js_kartra_santitation"
              data-santitation-type="name"
              aria-label="Preferred Gym"
              value="Club Bunker Newstead"
            />
          </div>

          <div style={{ marginLeft: "15px", marginRight: "15px" }}>
            <FormButtonSecondary
              style={{ alignItems: "center", justifyContent: "center" }}
              type="submit"
              enabled={
                this.state.validName &&
                this.state.validLastName &&
                this.state.validEmail &&
                this.state.validMobile &&
                this.state.validFitnessSituation &&
                this.state.validGoal &&
                this.state.validGoalTimeline &&
                this.state.validWhenStart
              }
              onClick={this.handleSubmitClick}>
              Share with my Coach
            </FormButtonSecondary>
          </div>
        </form>
      </div>
    )
  }
}
